import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OneAppDtoRequest } from '@shared-services/apps-dto-request/apps-dto-request.class';
import {
  DealersChatAgentHasChatGetEndpoint,
  ResponseType,
} from '@tes/apps-dto';
import { DealersChatAgentReadyPostEndpoint } from '@tes/apps-dto/dist/services/dealers/chat/dealers-chat-agent-ready-post.endpoint';
import { DealersChatAgentThreadUrlGetEndpoint } from '@tes/apps-dto/dist/services/dealers/chat/dealers-chat-agent-thread-url-get.endpoint';
import { DealersChatAgentDistributionPostEndpoint } from '@tes/apps-dto/dist/services/dealers/chat/dealers-chat-agent-distribution-post.endpoint';
import { map } from 'rxjs';

@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient) {}

  public readyAgent = () => {
    const request = new OneAppDtoRequest<
      DealersChatAgentReadyPostEndpoint.RequestBody,
      DealersChatAgentReadyPostEndpoint.RequestPathParams,
      object,
      DealersChatAgentReadyPostEndpoint.RequestHeaders,
      ResponseType<DealersChatAgentReadyPostEndpoint.ResponseData>
    >(this.http, {
      baseUrl: '',
      type: DealersChatAgentReadyPostEndpoint.type,
      url: DealersChatAgentReadyPostEndpoint.endPointPath,
      getUrl: DealersChatAgentReadyPostEndpoint.getUrl,
    });

    return request.execute({
      query: {},
      path: {},
      headers: {},
      body: undefined,
    });
  };

  public getThreadUrl = () => {
    const request = new OneAppDtoRequest<
      DealersChatAgentThreadUrlGetEndpoint.RequestBody,
      DealersChatAgentThreadUrlGetEndpoint.RequestPathParams,
      object,
      DealersChatAgentThreadUrlGetEndpoint.RequestHeaders,
      ResponseType<DealersChatAgentThreadUrlGetEndpoint.ResponseData>
    >(this.http, {
      baseUrl: '',
      type: DealersChatAgentThreadUrlGetEndpoint.type,
      url: DealersChatAgentThreadUrlGetEndpoint.endPointPath,
      getUrl: DealersChatAgentThreadUrlGetEndpoint.getUrl,
    });

    return request.execute({
      query: {},
      path: {},
      headers: {},
      body: undefined,
    });
  };

  public pushNotificationsByVin = (
    body: DealersChatAgentDistributionPostEndpoint.RequestBody,
    query: any = {}
  ) => {
    const request = new OneAppDtoRequest<
      DealersChatAgentDistributionPostEndpoint.RequestBody,
      DealersChatAgentDistributionPostEndpoint.RequestPathParams,
      object,
      DealersChatAgentDistributionPostEndpoint.RequestHeaders,
      ResponseType<DealersChatAgentDistributionPostEndpoint.ResponseData>
    >(this.http, {
      baseUrl: '',
      type: DealersChatAgentDistributionPostEndpoint.type,
      url: DealersChatAgentDistributionPostEndpoint.endPointPath,
      getUrl: DealersChatAgentDistributionPostEndpoint.getUrl,
    });

    return request.execute({
      query,
      path: {},
      headers: {},
      body,
    });
  };

  public getAgentHasChat = () => {
    const request = new OneAppDtoRequest<
      DealersChatAgentHasChatGetEndpoint.RequestBody,
      DealersChatAgentHasChatGetEndpoint.RequestPathParams,
      object,
      DealersChatAgentHasChatGetEndpoint.RequestHeaders,
      ResponseType<DealersChatAgentHasChatGetEndpoint.ResponseData>
    >(this.http, {
      baseUrl: '',
      type: DealersChatAgentHasChatGetEndpoint.type,
      url: DealersChatAgentHasChatGetEndpoint.endPointPath,
      getUrl: DealersChatAgentHasChatGetEndpoint.getUrl,
    });

    return request.execute({
      query: {},
      path: {},
      headers: {},
      body: undefined,
    });
  };

  public getAgentUnreadCount = () => {
    return this.http
      .get<{ data: { unread: number } }>(
        '/tes-dealers/chat/agents/unread-count'
      )
      .pipe(map((response) => response.data));
  };
}
