import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
  catchError,
  concatMap,
  map,
  Observable,
  of,
  withLatestFrom,
} from 'rxjs';
import {
  TokenControlService,
  TokenPackage,
} from 'src/app/services-global/token-service/token-control.service';
import { environment } from '@environment';
import { CleanerControlService } from '@global-services/cleaner-service/cleaner-control.service';
import { Router } from '@angular/router';

const BASE_URL = environment.baseUrl;

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly tokenControlService: TokenControlService,
    private readonly cleanerControlService: CleanerControlService,
    private readonly ngZone: NgZone,
    private readonly router: Router
  ) {}

  addToken(
    req: HttpRequest<any>,
    token: TokenPackage | null
  ): HttpRequest<any> {
    const bearerToken = token?.accessToken;
    return bearerToken
      ? req.clone({ setHeaders: { Authorization: `Bearer ${bearerToken}` } })
      : req;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return of(true).pipe(
      withLatestFrom(this.tokenControlService.currentAccessToken$),
      map(([, token]) => this.addToken(req, token)),
      concatMap((reqAuth) => next.handle(this.setCustomRequest(reqAuth))),
      catchError((error) => this.checkUnauthorizedError(error))
    );
  }

  private checkUnauthorizedError(error: unknown): never {
    const authorized = !!this.tokenControlService.currentAccessToken;
    if (
      error instanceof HttpErrorResponse &&
      error.status === HttpStatusCode.Unauthorized &&
      authorized
    ) {
      this.tokenControlService.removeToken();
      this.cleanerControlService.clean();

      this.ngZone.run(() => this.router.navigate(['login'])).then();
    }

    throw error;
  }

  private setCustomRequest(req: HttpRequest<any>): HttpRequest<any> {
    const headers = req.headers.append('X-Project', 'dealer-office');
    return req.clone({
      url:
        (req.url.includes('i18n') ||
        req.url.includes('assets') ||
        req.url.includes('https://') ||
        req.url.includes('http://')
          ? ''
          : BASE_URL) + req.url,
      headers,
    });
  }
}
