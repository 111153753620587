import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ProfileControlService } from '@global-services/profile-service/profile.service';
import { Observable, switchMap, tap } from 'rxjs';
import { ACTION_ROOT } from '@global-services/profile-service/shared/profile.interface';
import { environment } from '@environment';

class State {
  userRoles: string[] = [];
  production: boolean = environment.production;
}

@Injectable({ providedIn: 'root' })
export class AccessService extends ComponentStore<State> {
  get userRoles(): string[] {
    return this.get().userRoles;
  }

  constructor(private profileService: ProfileControlService) {
    super(new State());
    this.initRights();
  }

  private initRights = this.effect((origin$: Observable<void>) => {
    return origin$.pipe(
      switchMap(() => this.profileService.profile$),
      tap((profile) => this.patchState({ userRoles: profile?.roles || [] }))
    );
  });

  checkAccess(
    requiredRoles: string[] | undefined,
    denyRoles?: string[]
  ): boolean {
    const roles = this.get().userRoles;
    const approve =
      !requiredRoles?.length ||
      roles.some((role) => [ACTION_ROOT, ...requiredRoles].includes(role));
    const notApprove =
      !denyRoles?.length || !roles.some((role) => denyRoles.includes(role));

    return approve && notApprove;
  }
}
