import { SettingsStateModule } from './shared/settings-state.interface';

export class SettingsCommonChanged {
  public static readonly type = '[Settings Store] Common Changed';
  constructor(
    public readonly payload: Partial<
      Pick<SettingsStateModule, 'theme' | 'language'>
    >
  ) {}
}

export class SettingsLanguageSet {
  public static readonly type = '[Settings Store] Language Set';
}
