import { MatSnackBar } from '@angular/material/snack-bar';
import { IndexedDBService } from '@storages-services/indexed-db-service/indexed-db.service';
import { Lang } from '@shared-services/consts/lang';
import { ChangeDetectionStrategy, Component, Self } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { filter, takeUntil, tap } from 'rxjs';
import { UpdaterComponent } from '@components/updater/updater.component';
import { DestroyService } from '@shared-services/destroy.service';
import { Store } from '@ngxs/store';
import { SettingsState } from '@global-services/settings-service/settings.state';
import { environment } from '@environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'dealer-office';

  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
    public indexedDBService: IndexedDBService,
    private _snackBar: MatSnackBar,
    private store: Store,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.indexedDBService.initialitation();
    this.translateService.addLangs([Lang.En, Lang.Ru]);
    this.translateService.setDefaultLang(Lang.Ru);

    this.store
      .select(SettingsState.getLanguage)
      .pipe(
        tap((language) => this.translateService.use(language)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    ///нужно сделать красивее, сделано для проверки
    this.swUpdate.versionUpdates
      .pipe(
        filter((e) => e.type === 'VERSION_READY'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.openUpdateBar();
      });

    if (!environment.isLocal) {
      setInterval(() => {
        this.swUpdate.checkForUpdate().then();
      }, 30000);
    }
  }

  openUpdateBar(): void {
    this._snackBar.openFromComponent(UpdaterComponent, {
      panelClass: 'updateMessage',
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
    });
  }
}
