import { SwUpdate } from '@angular/service-worker';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';

@Component({
  template: `
    <span class="title-text">Доступно обновление.</span>
    <button
      mat-stroked-button
      class="update-btn"
      color="primary"
      (click)="update()"
    >
      Обновить
    </button>
    <button mat-icon-button class="close-btn" (click)="close()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  `,
})
export class UpdaterComponent {
  constructor(
    private _snackBar: MatSnackBar,
    private store: Store,
    private readonly swUpdate: SwUpdate
  ) {}

  update() {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  close() {
    this._snackBar._openedSnackBarRef?.dismiss();
  }
}
