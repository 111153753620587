import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexedDBService } from './indexed-db.service';
import { OfficeStorageModule } from '@storages-services/office-storage/office-storage.module';
import { CurrentOfficeModule } from '@storages-services/current-office-storage/current-office-storage.module';
import { DexieDbServiceModule } from '@storages-services/dexie-db-storage/dexie-db-storage-service.module';

const STORAGE_SERVICES = [
  CurrentOfficeModule,
  DexieDbServiceModule,
  OfficeStorageModule,
];

@NgModule({
  imports: [CommonModule, ...STORAGE_SERVICES],
  providers: [IndexedDBService],
})
export class IndexedDBModule {}
