import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnInit, Self } from '@angular/core';
import { Theme } from './theme.const';
import { takeUntil } from 'rxjs';
import { DestroyService } from '@shared-services/destroy.service';
import { Store } from '@ngxs/store';
import { SettingsState } from '@global-services/settings-service/settings.state';

@Directive({ selector: '[appTheme]', providers: [DestroyService] })
export class ThemesDirective implements OnInit {
  constructor(
    private readonly store: Store,
    @Self() private readonly destroy$: DestroyService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store
      .select(SettingsState.getTheme)
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme: Theme) => {
        this.updateTheme(theme);
      });

    // this.updateTheme(Theme.Light);
  }

  updateTheme(theme: Theme) {
    this.document.body.classList.remove(Theme.Dark);
    this.document.body.classList.remove(Theme.Light);
    this.document.body.classList.add(theme);
    const themeMeta = this.document.querySelector(
      'meta[name=theme-color]'
    ) as any;
    if (themeMeta) {
      themeMeta.content = theme === Theme.Dark ? '#40484c' : 'white';
    }
  }
}
