import { Profile } from './shared/profile.interface';

export class SetProfileAction {
  public static readonly type = '[Profile Store] Set Profile';
  constructor(public profile: Profile) {}
}

export class ImpersonateProfileAction {
  public static readonly type = '[Profile Store] Impersonate Profile';
  constructor(public profile: Profile) {}
}

export class ResetImpersonationProfileAction {
  public static readonly type = '[Profile Store] Reset Impersonation Profile';
}

export class ResetProfileAction {
  public static readonly type = '[Profile Store] Reset Profile';
}
