import { IndexedDBService } from '@storages-services/indexed-db-service/indexed-db.service';
import { ProfileControlService } from '@global-services/profile-service/profile.service';
import { Injectable } from '@angular/core';
import { forkJoin, tap } from 'rxjs';

@Injectable()
export class CleanerControlService {
  constructor(
    private profileControlService: ProfileControlService,
    private indexedDBService: IndexedDBService
  ) {}

  clean() {
    this.profileControlService.clean();
    return forkJoin([this.indexedDBService.clean()]);
  }

  cleanDb() {
    return forkJoin([
      this.indexedDBService
        .clean()
        .pipe(tap(() => console.log('indexedDb cleaned'))),
    ]);
  }
}
