import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  targets = new Map<string, boolean>();
  targetsChangedSubject = new Subject<void>();

  loading$: Observable<boolean> = this.targetsChangedSubject
    .pipe(
      map(() => Array.from(this.targets.values())),
      map((targets) => targets.length > 0 && targets.some(Boolean))
    )
    .pipe(distinctUntilChanged());

  setLoading(target: string, value: boolean): void {
    if (value) {
      this.targets.set(target, value);
    } else {
      this.targets.delete(target);
    }
    this.targetsChangedSubject.next();
  }
}
