import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessService } from '@global-services/access/access.service';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private accessService: AccessService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuth(
      route.data?.['rights'] || [],
      route.data?.['noRights'],
      route
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuth(
      route.data?.['rights'] || [],
      route.data?.['noRights'],
      route
    );
  }

  checkAuth(
    guardRoles: string[],
    denyRoles?: string[],
    route?: Route | ActivatedRouteSnapshot
  ) {
    const isValid = this.accessService.checkAccess(guardRoles, denyRoles);

    if (isValid) {
      return true;
    } else {
      if (route && 'path' in route && route.path !== 'installer') {
        this.router.navigate(['/installer']);
      } else {
        this.router.navigate(['']);
      }
      return false;
    }
  }
}
