import { HttpErrorResponse } from '@angular/common/http';
import { isApiExceptionPayload } from '@shared-services/exceptions/api-exception-payload.interface';

export function isApiException(error: unknown, code: string): boolean {
  return (
    error instanceof HttpErrorResponse &&
    isApiExceptionPayload(error.error) &&
    error.error.meta.code === code
  );
}
