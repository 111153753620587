import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsStateService } from '@global-services/notifications-service/notifications.state.service';
import { NotificationsService } from '@global-services/notifications-service/notifications.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [NotificationsStateService, NotificationsService],
})
export class NotificationsServiceModule {}
