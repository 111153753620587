import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ResetProfileAction } from './profile.actions';
import { ProfileState } from './profile.state';
import { Observable } from 'rxjs';
import { Profile } from '@global-services/profile-service/shared/profile.interface';

@Injectable()
export class ProfileControlService {
  @Select(ProfileState.getProfile) profile$!: Observable<Profile | null>;
  @Select(ProfileState.getHostProfile)
  hostProfile$!: Observable<ProfileState | null>;

  constructor(private store: Store) {}

  get profile() {
    return this.store.selectSnapshot(ProfileState.getProfile);
  }

  clean() {
    this.store.dispatch(new ResetProfileAction());
  }
}
