import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  DealersDealerListItem,
  DealersDealerOfficeListItem,
} from '@tes/apps-dto';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { Dealer } from '@global-services/profile-service/shared/profile.interface';
import { ProfileControlService } from '@global-services/profile-service/profile.service';
import { OfficeStorageService } from '../office-storage/office-storage.service';

class State {
  loading: boolean = false;
}

@Injectable({ providedIn: 'root' })
export class CurrentOfficeService extends ComponentStore<State> {
  //текущие дилеры
  public curentDealers$: Observable<Dealer[]> =
    this.profileService.profile$.pipe(map((profile) => profile?.dealers || []));

  private combineLatestDealers = combineLatest([
    this.officeStorageService.listDealers$,
    this.curentDealers$,
  ]);
  //текущие офисы, расчитываются на основе текущих дилеров и всего списка офисов
  public listOffices$: Observable<DealersDealerOfficeListItem[]> =
    this.combineLatestDealers.pipe(
      map(([listDealers, curentDealers]) =>
        this.dealerMap(listDealers, curentDealers)
      )
    );

  constructor(
    private profileService: ProfileControlService,
    private officeStorageService: OfficeStorageService
  ) {
    super(new State());
  }

  //загразка списка офисов
  public loadOffices() {
    this.officeStorageService.loadOffices();
  }

  private dealerMap = (
    listDealers: DealersDealerListItem[],
    curentDealers: Dealer[]
  ) =>
    listDealers
      .filter((dealer) =>
        curentDealers.find(
          (curentDealer) => dealer.sapCode === curentDealer.code
        )
      )
      .reduce(
        (acc: DealersDealerOfficeListItem[], dealer: DealersDealerListItem) => {
          return [...acc, ...dealer.offices];
        },
        []
      ) || [];
}
