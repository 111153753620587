export const ACTION_VIEW_VEHICLE_LIST_MENU = 'ACTION_VIEW_VEHICLE_LIST_MENU';
export const ACTION_VIEW_VEHICLE_MAP_MENU = 'ACTION_VIEW_VEHICLE_MAP_MENU';
export const ACTION_VIEW_SERVICE_VISITS_MENU =
  'ACTION_VIEW_SERVICE_VISITS_MENU';
export const ACTION_VIEW_SERVISE_VISITS_GEOZONES_MENU =
  'ACTION_VIEW_SERVISE_VISITS_GEOZONES_MENU';
export const ACTION_VIEW_VEHICLES_BUYOUTS_MENU =
  'ACTION_VIEW_VEHICLES_BUYOUTS_MENU';
export const ACTION_VIEW_FEEDBACK_FORM_MENU = 'ACTION_VIEW_FEEDBACK_FORM_MENU';
export const ACTION_VIEW_FEEDBACK_LIST_MENU = 'ACTION_VIEW_FEEDBACK_LIST_MENU';
export const ACTION_ROOT = 'ACTION_ROOT';
export const ACTION_VIEW_DASHBOARD_MENU = 'ACTION_VIEW_DASHBOARD_MENU';
export const ACTION_PAID = 'ACTION_PAID';
export const ACTION_CHAT = 'ACTION_CHAT';
/** Доступ на добавление авто */
export const ACTION_ADD_VEHICLE = 'ACTION_ADD_VEHICLE';
/** Доступ на просмотр устройств телематики */
export const ACTION_VIEW_DEVICES = 'ACTION_VIEW_DEVICES';
/** Доступ на добавление устройств телематики */
export const ACTION_ADD_DEVICES = 'ACTION_ADD_DEVICES';
/** Доступ техподдержки */
export const ACTION_SUPPORT = 'ACTION_SUPPORT';
/** Доступ оператора мобильного шиномонтажа */
export const ACTION_TIRE_FITTING = 'ACTION_TIRE_FITTING';
/** Доступ к заявкам на сервис */
export const ACTION_TO_LEADS = 'ACTION_TO_LEADS';
/** Доступ к конфигам заявок на ремонт устройств */
export const ACTION_REPAIR_TICKET_CONFIG = 'ACTION_REPAIR_TICKET_CONFIG';
/** Доступ к заявкам на покупку авто */
export const ACTION_CAR_BUY_TICKETS = 'ACTION_CAR_BUY_TICKETS';

// export const PROFILE_VERSION = 0;
export const PROFILE_VERSION = 12;

export interface Profile {
  name: string;
  email: string;
  transport_park?: string;
  fio?: string;
  roles: string[];
  // dealer: { code: string; name: string; dealerName: string };
  dealers: Dealer[];
  version?: typeof PROFILE_VERSION;
  logoUrl: string | null;
}

export interface Dealer {
  code: string;
  name: string;
  dealerName: string;
}
