import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpMethod } from '@tes/apps-dto';

export interface OneAppDtoRequestPayload<
  ReqBody,
  ReqPath,
  ReqQuery,
  ReqHeaders
> {
  body: ReqBody;
  path: ReqPath;
  query: ReqQuery;
  headers: ReqHeaders;
}

export interface OneAppDtoRequestOptions<ReqPath> {
  baseUrl: string;
  type: HttpMethod;
  getUrl?: (params: ReqPath) => string;
  url?: string;
}

import { HttpParams } from '@angular/common/http';

export function buildHttpParams<T extends object>(
  obj?: T
): HttpParams | undefined {
  let params = new HttpParams();

  if (typeof obj !== 'object') {
    return undefined;
  }

  Object.keys(obj).forEach((key: string) => {
    const value =
      typeof (<Record<string, unknown>>obj)[key] === 'string'
        ? ((<Record<string, unknown>>obj)[key] as string).trim()
        : (<Record<string, unknown>>obj)[key];

    if (value || value === false || value === 0) {
      if (Array.isArray(value)) {
        value.forEach((data) => (params = params.append(`${key}`, data)));
      } else {
        params = params.append(`${key}`, value as string);
      }
    }
  });

  return params;
}

export class OneAppDtoRequest<ReqBody, ReqPath, ReqQuery, ReqHeaders, Res> {
  constructor(
    protected httpClient: HttpClient,
    protected options: OneAppDtoRequestOptions<ReqPath>
  ) {}

  public execute(
    payload: OneAppDtoRequestPayload<ReqBody, ReqPath, ReqQuery, ReqHeaders>
  ): Observable<Res> {
    const route =
      typeof this.options.getUrl === 'function'
        ? this.options.getUrl(payload.path)
        : this.options.url ?? '';

    const url = `${this.options.baseUrl.replace(
      new RegExp(/\/$/),
      ''
    )}/${route.replace(new RegExp(/^\//), '')}`;

    return this.httpClient.request<Res>(this.options.type, url, {
      body: payload.body,
      params: buildHttpParams(payload.query as never),
      headers: payload.headers as never,
      responseType: 'json',
      observe: 'body',
    });
  }
}
