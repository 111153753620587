import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ProfileControlService } from '@global-services/profile-service/profile.service';
import {
  catchError,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { NotificationsService } from '@global-services/notifications-service/notifications.service';
import { DealersChatAgentDistributionPostEndpoint } from '@tes/apps-dto/dist/services/dealers/chat/dealers-chat-agent-distribution-post.endpoint';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime } from 'rxjs/operators';

class State {
  threadUrl: string | null = null;
  hasChat: boolean = false;
  unreadCount: number = 0;
}

@Injectable()
export class NotificationsStateService extends ComponentStore<State> {
  public threadUrl$ = this.select((s) => s.threadUrl);
  public unreadCount$ = this.select((s) => s.unreadCount);

  constructor(
    private profileService: ProfileControlService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar
  ) {
    super(new State());
    this.initAgent();
    this.hasChatChangedEffect();
    this.checkUnreadCountByIntervalEffect();
    this.threadUrlParamsChangedEffect();
  }

  resetUnread(): void {
    this.patchState({ unreadCount: 0 });
  }

  getThreadUrlEffect = this.effect((origin$) => {
    return origin$.pipe(
      switchMap(() => this.notificationsService.getThreadUrl()),
      tap(({ data }) => {
        this.patchState({ threadUrl: data.threadUrl });
      }),
      catchError((e) => {
        console.error(e);
        return origin$;
      })
    );
  });

  private hasChatChangedEffect = this.effect((origin$) =>
    origin$.pipe(
      switchMap(() => this.select((s) => s.hasChat)),
      filter(Boolean),
      tap(() => this.setAgentReady())
    )
  );

  private setAgentReady = this.effect((origin$) => {
    return origin$.pipe(
      switchMap(() => this.notificationsService.readyAgent()),
      catchError((e) => {
        console.error(e);
        return origin$;
      })
    );
  });

  private initAgent = this.effect((origin$: Observable<void>) => {
    return origin$.pipe(
      switchMap(() => this.profileService.profile$),
      switchMap(() =>
        this.notificationsService.getAgentHasChat().pipe(
          map((response) => response.data.hasChat),
          catchError(() => of(false))
        )
      ),
      tap((hasChat) => this.patchState({ hasChat }))
    );
  });

  private threadUrlParamsChangedEffect = this.effect((origin$) =>
    origin$.pipe(
      switchMap(() => this.select((s) => s.hasChat)),
      tap(() => this.patchState({ threadUrl: null })),
      filter(Boolean),
      debounceTime(300),
      switchMap(() => this.unreadCount$),
      tap(() => this.getThreadUrlEffect())
    )
  );

  private checkUnreadCountByIntervalEffect = this.effect((origin$) =>
    origin$.pipe(
      switchMap(() => this.select((s) => s.hasChat)),
      tap(() => this.patchState({ unreadCount: 0 })),
      filter(Boolean),
      switchMap(() => timer(0, 30000)),
      switchMap(() =>
        this.notificationsService.getAgentUnreadCount().pipe(
          tap(({ unread }) => this.patchState({ unreadCount: unread })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public pushNotifications = this.effect((origin$: Observable<any>) => {
    return origin$.pipe(
      switchMap((req) =>
        this.notificationsService
          .pushNotificationsByVin(req.body, req.query)
          .pipe(
            tap(() => {
              this.snackBar.open('Рассылка клиентам прошла успешно', '', {
                panelClass: 'okMessage',
                duration: 4000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
              });
            })
          )
      ),
      catchError((e) => {
        console.error(e);
        return origin$;
      })
    );
  });
}
