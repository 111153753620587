import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { DealersDealerListItem } from '@tes/apps-dto';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, switchMap } from 'rxjs';
import { IndexedDBService } from '@storages-services/indexed-db-service/indexed-db.service';
import { catchError } from 'rxjs';
import { DealerOfficeApiService } from '@shared-services/api/dealer-office/dealer-office-api.service';

class State {
  listDealers: DealersDealerListItem[] = [];
  loading: boolean = false;
}

@Injectable()
export class OfficeStorageService extends ComponentStore<State> {
  private storeNames = 'dealersList';
  private db: IDBDatabase = this.indexedDBService.db;
  //текущие дилеры
  public listDealers$ = this.select((e) => e.listDealers);

  constructor(
    private readonly indexedDBService: IndexedDBService,
    private readonly dealerOfficeApi: DealerOfficeApiService
  ) {
    super(new State());
    this.initial();
  }

  //загразка списка офисов
  public loadOffices = this.effect((o: Observable<undefined>) =>
    o.pipe(switchMap(() => this.updateOffifceList()))
  );

  private writeToDb(listAll: DealersDealerListItem[]) {
    const vehicles = this.getDBObjectStore(
      this.db,
      this.storeNames,
      'readwrite'
    );
    vehicles.clear();
    listAll.forEach((vehicle) => vehicles.put(vehicle));
  }

  private initial() {
    const listAllRequest = this.getDBObjectStore(
      this.db,
      this.storeNames
    ).getAll();

    listAllRequest.onsuccess = () => {
      if (listAllRequest.result !== undefined) {
        if (listAllRequest.result[0]) {
          this.patchState({
            listDealers: listAllRequest.result,
          });
        } else {
          this.loadOffices();
        }
      } else {
        this.loadOffices();
      }
    };
  }

  private updateOffifceList() {
    return this.dealerOfficeApi.getOfficeList().pipe(
      map((response) => response.data.dealers),
      tap((listAll) => this.writeToDb(listAll)),
      tap((listAll) =>
        this.patchState({
          listDealers: listAll,
        })
      ),
      catchError((error) => of(error))
    );
  }

  private getDBObjectStore(
    db: IDBDatabase,
    storeNames: string,
    mode: IDBTransactionMode = 'readonly'
  ): IDBObjectStore {
    let transaction = db.transaction(storeNames, mode);
    return transaction.objectStore(storeNames);
  }
}
