import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  Observable,
  of,
  switchMap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { TokenControlService } from '@global-services/token-service/token-control.service';
import { isApiException } from '@shared-services/exceptions/is-api-exception.function';
import { ApiExceptionCodeEnum } from '@shared-services/exceptions/api-exception-code.enum';

@Injectable({ providedIn: 'root' })
export class AuthPingService {
  protected readonly sessionBlockedSubject = new BehaviorSubject<boolean>(
    false
  );

  public readonly sessionBlocked$ = this.sessionBlockedSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly tokenControlService: TokenControlService
  ) {}

  startPing(): void {
    timer(0, 30 * 1000)
      .pipe(
        withLatestFrom(this.tokenControlService.currentAccessToken$),
        map(([_, pack]) => pack?.accessToken),
        filter((token) => !!token),
        switchMap(() => this.pingRequest())
      )
      .subscribe((sessionBlocked) =>
        this.sessionBlockedSubject.next(sessionBlocked)
      );
  }

  pingRequest(): Observable<boolean> {
    return this.http.post(`/tes-dealers/auth/ping`, {}).pipe(
      map(() => false),
      catchError((error) => {
        return of(
          isApiException(error, ApiExceptionCodeEnum.AnotherActiveSession)
        );
      })
    );
  }
}
