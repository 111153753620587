import { VehicleFullModelInterface } from '@tes/apps-dto';
import Dexie, { Table } from 'dexie';

export const DB_NAME = 'dexieDealerVehicleDB2022';

//индексированные поля. только по ним можно искать и сортировать. при попытке что то делать с другими полями - будет ошибка
export enum INDEXES {
  VIN = 'vin',
  BRAND_NAME = 'brand.name',
  MODEL_NAME = 'modelName.name',
  MOTO_HOURS = 'motoHours',
  DEALER_OFFICE_NAME = 'dealerOfficeName',
  DRIVING_STYLE = 'drivingStyle',
  ADDRESS = 'address',
  RETAIL_DATE = 'retailDate',
  ASSESSED_PRICE = 'assessedPrice',
  DEALER_OFFICE_SAP_CODE = 'dealerOfficeSapCode',
  DEVICE_TYPE_NAME = 'deviceType.name',
  VOLTAGE = 'voltage',
  MILEAGE = 'mileage',
  STATUS_NAME = 'status.name',
  ACTIVATION_DATE = 'activationDate',
  GENERATION = 'generation.ru',
  ISSUE_YEAR = 'issueYear',
  LAST_MAINTENANCE_DATE = 'lastMaintenanceDate',
  LAST_MAINTENANCE_NAME = 'lastMaintenanceName',
  FAVORITE_DEALER_OFFICE_TES_NAME = 'favoriteDealerOfficeTesName',
  FAVORITE_DEALER_OFFICE_MOBIKEY_NAME = 'favoriteDealerOfficeMobikeyName',
}

export class AppDB extends Dexie {
  vehicleList!: Table<VehicleFullModelInterface, string>;

  constructor() {
    super(DB_NAME);

    this.version(1).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE,
    });
    this.version(2).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE,
    });
    this.version(3).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE +
        ', ' +
        INDEXES.GENERATION +
        ', ' +
        INDEXES.ISSUE_YEAR,
    });
    this.version(4).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE +
        ', ' +
        INDEXES.GENERATION +
        ', ' +
        INDEXES.ISSUE_YEAR +
        ', ' +
        INDEXES.LAST_MAINTENANCE_DATE +
        ', ' +
        INDEXES.LAST_MAINTENANCE_NAME,
    });
    this.version(5).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE +
        ', ' +
        INDEXES.GENERATION +
        ', ' +
        INDEXES.ISSUE_YEAR +
        ', ' +
        INDEXES.LAST_MAINTENANCE_DATE +
        ', ' +
        INDEXES.LAST_MAINTENANCE_NAME +
        ', ' +
        'favoriteDealerOfficeTesName',
    });
    this.version(6).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE +
        ', ' +
        INDEXES.GENERATION +
        ', ' +
        INDEXES.ISSUE_YEAR +
        ', ' +
        INDEXES.LAST_MAINTENANCE_DATE +
        ', ' +
        INDEXES.LAST_MAINTENANCE_NAME +
        ', ' +
        INDEXES.FAVORITE_DEALER_OFFICE_TES_NAME +
        ', ' +
        INDEXES.FAVORITE_DEALER_OFFICE_MOBIKEY_NAME,
    });
    this.version(7).stores({
      vehicleList:
        '&' +
        INDEXES.VIN +
        ', ' +
        INDEXES.BRAND_NAME +
        ', ' +
        INDEXES.MODEL_NAME +
        ', ' +
        INDEXES.MOTO_HOURS +
        ', ' +
        INDEXES.DEALER_OFFICE_NAME +
        ', ' +
        INDEXES.DRIVING_STYLE +
        ', ' +
        INDEXES.ADDRESS +
        ', ' +
        INDEXES.RETAIL_DATE +
        ', ' +
        INDEXES.ASSESSED_PRICE +
        ', ' +
        INDEXES.DEALER_OFFICE_SAP_CODE +
        ', ' +
        INDEXES.DEVICE_TYPE_NAME +
        ', ' +
        INDEXES.VOLTAGE +
        ', ' +
        INDEXES.MILEAGE +
        ', ' +
        INDEXES.STATUS_NAME +
        ', ' +
        INDEXES.ACTIVATION_DATE +
        ', ' +
        INDEXES.GENERATION +
        ', ' +
        INDEXES.ISSUE_YEAR +
        ', ' +
        INDEXES.LAST_MAINTENANCE_DATE +
        ', ' +
        INDEXES.LAST_MAINTENANCE_NAME +
        ', ' +
        INDEXES.FAVORITE_DEALER_OFFICE_TES_NAME +
        ', ' +
        INDEXES.FAVORITE_DEALER_OFFICE_MOBIKEY_NAME,
    });
  }
}

export const db = new AppDB();
