import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ThemesModule } from 'src/app/services-global/themes-service/themes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { SettingsService } from './settings.service';
import { SettingsState } from './settings.state';

@NgModule({
  imports: [
    BrowserModule,
    NgxsModule.forFeature([SettingsState]),
    ThemesModule,
    TranslateModule,
  ],
  providers: [SettingsService],
})
export class SettingsStoreModule {}
