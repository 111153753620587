import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SettingsStateModule } from './shared/settings-state.interface';
import { SettingsCommonChanged, SettingsLanguageSet } from './settings.actions';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from 'src/app/services-global/themes-service/theme.const';
import { Lang } from 'src/app/services-shared/consts/lang';

const INITIAL_SETTINGS_STATE: SettingsStateModule = {
  loading: false,
  loaded: false,
  language: undefined,
  theme: Theme.Light,
  timezone: '+03:00',
};

@State<SettingsStateModule>({
  name: 'settings',
  defaults: INITIAL_SETTINGS_STATE,
})
@Injectable()
export class SettingsState {
  @Selector()
  public static getLanguage(state: SettingsStateModule): Lang {
    return state.language!;
  }

  @Selector()
  public static getTheme(state: SettingsStateModule): Theme {
    return state.theme || Theme.Light;
  }

  @Selector()
  public static getCommonSettings(
    state: SettingsStateModule
  ): Pick<SettingsStateModule, 'language' | 'theme'> {
    const { language, theme = Theme.Light } = state;
    return { language, theme };
  }
  @Action(SettingsCommonChanged)
  settingsCommonChanged(
    { patchState }: StateContext<SettingsStateModule>,
    { payload }: SettingsCommonChanged
  ) {
    patchState({ ...payload });
  }

  @Action(SettingsLanguageSet)
  settingsLanguageSet({ patchState }: StateContext<SettingsStateModule>) {
    patchState({ language: Lang.Ru });
  }
}
