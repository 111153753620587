import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface TokenPackage {
  accessToken: string;
  hostAccessToken?: string;
}

@Injectable()
export class TokenControlService {
  private get localStorageToken(): TokenPackage {
    const objectString = localStorage.getItem('tokenPackage') || 'null';
    return JSON.parse(objectString);
  }

  private accessTokenSubject$ = new BehaviorSubject<TokenPackage | null>(
    this.localStorageToken
  );

  public currentAccessToken$: Observable<TokenPackage | null> =
    this.accessTokenSubject$.asObservable();

  public get currentAccessToken(): TokenPackage | null {
    return this.accessTokenSubject$.getValue();
  }

  setToken(value: TokenPackage) {
    const valueString = value ? JSON.stringify(value) : '';
    localStorage.setItem('tokenPackage', valueString);
    this.accessTokenSubject$.next(value);
  }

  removeToken() {
    localStorage.removeItem('tokenPackage');
    this.accessTokenSubject$.next(null);
  }
}
