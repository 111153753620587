import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AuthPingService } from '@global-services/auth-service/auth-ping.service';
import { LoaderService } from '@pages-core/main-container-page/loader.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    LoaderService,
    AuthService,
    AuthPingService,
    {
      provide: APP_INITIALIZER,
      useFactory: (pingService: AuthPingService) => () =>
        pingService.startPing(),
      deps: [AuthPingService],
      multi: true,
    },
  ],
})
export class AuthControlModule {}
