import { AuthGuard } from '@guards/auth.guard';
import { NotAuthGuard } from '@guards/not-auth.guard';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '@guards/access.guard';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages-core/login-page/login-page.module').then(
        (m) => m.LoginPageModule
      ),
    canActivate: [NotAuthGuard],
    canLoad: [NotAuthGuard],
  },
  {
    path: 'installer',
    loadChildren: () =>
      import(
        '@pages-core/installer-container-page/installer-container-page.module'
      ).then((m) => m.InstallerContainerPageModule),
    data: {
      rights: ['DEVICE_INSTALLER'],
    },
    canActivate: [AuthGuard, AccessGuard],
    canLoad: [AccessGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import(
        './pages-core/main-container-page/main-container-page.module'
      ).then((m) => m.MainContainerPageModule),
    canActivate: [AuthGuard, AccessGuard],
    canLoad: [AuthGuard, AccessGuard],
    data: {
      noRights: ['DEVICE_INSTALLER'],
    },
  },
  //     {
  //       path: 'settings',
  //       loadChildren: () =>
  //         import('./pages/settings-page/settings-page.module').then(
  //           (m) => m.SettingsPageModule
  //         ),
  //     },
  //     {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: '/main',
  // },
  //   ],
  //   canActivate: [AuthGuard],
  //   canLoad: [AuthGuard],
  // },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/start',
  },
  // {
  //   path: '**',
  //   loadChildren: () =>
  //     import('./pages/not-found-page/not-found-page.module').then(
  //       (m) => m.NotFoundPageModule
  //     ),
  //   canActivate: [AuthGuard],
  //   canLoad: [AuthGuard],
  // },
];
const ROUTER_EXTRA_CONFIG: ExtraOptions = {
  relativeLinkResolution: 'legacy',
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, ROUTER_EXTRA_CONFIG)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
