import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemesDirective } from './theme.directive';

@NgModule({
  declarations: [ThemesDirective],
  imports: [CommonModule],
  exports: [ThemesDirective],
})
export class ThemesModule {}
