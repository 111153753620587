import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DestroyService } from 'src/app/services-shared/destroy.service';
import { NgxsModule } from '@ngxs/store';
import { ProfileControlService } from './profile.service';
import { ProfileState } from './profile.state';
@NgModule({
  imports: [BrowserModule, NgxsModule.forFeature([ProfileState])],
  providers: [ProfileControlService, DestroyService],
})
export class ProfileControlModule {}
