import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';

import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeen from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeru from '@angular/common/locales/ru';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthControlModule } from '@global-services/auth-service/auth-service.module';
import { ProfileControlModule } from '@global-services/profile-service/profile-control.module';
import { SettingsStoreModule } from '@global-services/settings-service/settings-service.module';
import { ThemesModule } from '@global-services/themes-service/themes.module';
import { TokenControlModule } from '@global-services/token-service/token-control.module';
import { Lang } from 'src/app/services-shared/consts/lang';
import { environment } from '@environment';
import { AppHttpInterceptor } from './app-http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexedDBModule } from '@storages-services/indexed-db-service/indexed-db.module';
import { UpdaterModule } from '@components/updater/updater.module';
import { CleanerControlModule } from '@global-services/cleaner-service/cleaner-control.module';
import { AccessModule } from '@global-services/access/access.module';
import { NotificationsServiceModule } from '@global-services/notifications-service/notifications-service.module';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import dateFnsRu from 'date-fns/locale/ru';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localeru, localeRuExtra);
registerLocaleData(localeen, localeEnExtra);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function getcurrentLang(): string {
  return (
    JSON.parse(localStorage.getItem('settings') || '{}').language || Lang.Ru
  );
}

const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.isLocal,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    //основыные модули
    HttpClientModule,

    //обновление
    UpdaterModule,

    // ngxs
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    // NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),

    // NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['profile'],
    }),

    // тема, язык
    ThemesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SettingsStoreModule,
    NotificationsServiceModule,
    //сервис IndexedDB
    IndexedDBModule,
    //сервисы для авторизации и профиля
    AuthControlModule,
    AccessModule,
    // AuthControlMockModule,
    ProfileControlModule,
    TokenControlModule,
    CleanerControlModule,
    // глобальный стор
    NgxsStoragePluginModule.forRoot({
      // key: ['VehicleList'],
    }),
    MatDateFnsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: getcurrentLang() },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: dateFnsRu,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
