import { Injectable } from '@angular/core';
import { DB_NAME } from './db';

@Injectable({ providedIn: 'root' })
export class DexieDbService {
  constructor() {
    indexedDB.databases().then((databases) => {
      databases.some((i) => i.name === DB_NAME);
      const deleteRequest = indexedDB.deleteDatabase(DB_NAME);

      deleteRequest.onerror = (error) => {
        console.log('Не удалось удалить локальную БД:', error);
      };
    });
  }
}
