import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetProfileAction,
  ResetProfileAction,
  ImpersonateProfileAction,
  ResetImpersonationProfileAction,
} from './profile.actions';
import { Profile } from './shared/profile.interface';

export interface ProfileStateModel {
  profile: Profile | null;
  hostProfile: Profile | null;
}

const INITIAL_PROFILE_STATE: ProfileStateModel = {
  profile: null,
  hostProfile: null,
};

@State<ProfileStateModel>({
  name: 'profile',
  defaults: INITIAL_PROFILE_STATE,
})
@Injectable()
export class ProfileState {
  @Selector()
  static getHostProfile(state: ProfileStateModel): Profile | null {
    return state.hostProfile;
  }

  @Selector()
  static getProfile(state: ProfileStateModel): Profile | null {
    return state.profile;
  }

  @Action(SetProfileAction)
  loadingProfileAction(
    { patchState }: StateContext<ProfileStateModel>,
    { profile }: SetProfileAction
  ) {
    patchState({ profile });
  }

  @Action(ImpersonateProfileAction)
  impersonateProfileAction(
    { getState, patchState }: StateContext<ProfileStateModel>,
    { profile }: ImpersonateProfileAction
  ) {
    const state = getState();
    patchState({
      profile,
      hostProfile: state.profile,
    });
  }

  @Action(ResetImpersonationProfileAction)
  resetImpersonationProfileAction({
    getState,
    patchState,
  }: StateContext<ProfileStateModel>) {
    const state = getState();
    patchState({
      profile: state.hostProfile,
      hostProfile: null,
    });
  }

  @Action(ResetProfileAction)
  resetProfileAction({ setState }: StateContext<ProfileStateModel>) {
    setState({ profile: null, hostProfile: null });
  }
}
