import {
  BehaviorSubject,
  filter,
  map,
  merge,
  Observable,
  Subject,
  take,
  tap,
} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IndexedDBService {
  public db!: IDBDatabase;

  //сажект сообщаяет когда база инициализирована - можно инициализировать главный компонент
  private _dbReady = new BehaviorSubject<boolean>(false);
  public dbReady = this._dbReady.asObservable();
  constructor() {}

  initialitation() {
    const openRequest = indexedDB.open('dealerVehicleDB', 54);

    openRequest.onerror = () => {
      console.error('Error', openRequest.error);
    };

    openRequest.onsuccess = () => {
      const db = openRequest.result;
      this.db = db;
      this._dbReady.next(true);
      // продолжить работу с базой данных, используя объект db
      db.onversionchange = () => {
        db.close();
        alert('База данных устарела, пожалуйста, перезагрузите страницу.');
      };
    };
    openRequest.onblocked = () => {
      alert('более новая версия не может быть загружена.');
      // это событие не должно срабатывать, если мы правильно обрабатываем onversionchange

      // это означает, что есть ещё одно открытое соединение с той же базой данных
      // и он не был закрыт после того, как для него сработал db.onversionchange
    };
    openRequest.onupgradeneeded = (event) => {
      // this.request = event.target;
      let db = openRequest.result;
      if (!db.objectStoreNames.contains('dealerVehicleList')) {
        const dealerVehicleList = db.createObjectStore('dealerVehicleList', {
          keyPath: 'vin',
        });
        dealerVehicleList.createIndex('status', ['status.name'], {
          unique: false,
        });
      }
      if (!db.objectStoreNames.contains('dealersList')) {
        db.createObjectStore('dealersList', {
          keyPath: 'sapCode',
        });
      }

      if (!db.objectStoreNames.contains('serviceVisitList')) {
        db.createObjectStore('serviceVisitList', {
          autoIncrement: true,
          // keyPath: 'vin',
        });
      }

      if (!db.objectStoreNames.contains('geozonetList')) {
        db.createObjectStore('geozonetList', {
          keyPath: 'externalId',
        });
      }

      if (db.objectStoreNames.contains('dealerVehicleList')) {
        var request = event.target as IDBRequest;
        var txn = request?.transaction as IDBTransaction;
        var store = txn.objectStore('dealerVehicleList');
        if (!store.indexNames.contains('status')) {
          store.createIndex('status', 'status.name', { unique: false });
        }
      }

      if (!db.objectStoreNames.contains('vehicleComments')) {
        db.createObjectStore('vehicleComments', {
          keyPath: 'vin',
        });
      }
      switch (
        event.oldVersion // существующая (старая) версия базы данных
      ) {
        case 0:
        // версия 0 означает, что на клиенте нет базы данных
        // выполнить инициализацию
        case 1:
        // на клиенте версия базы данных 1
        // @ts-ignore: Unreachable code error
        case 28:
          if (db.objectStoreNames.contains('geozonetList')) {
            db.deleteObjectStore('geozonetList');
            db.createObjectStore('geozonetList', {
              keyPath: 'externalId',
            });
          }
        // @ts-ignore: Unreachable code error
        case 30:
          if (db.objectStoreNames.contains('dealerVehicleList')) {
            var request = event.target as IDBRequest;
            var txn = request?.transaction as IDBTransaction;
            var store = txn.objectStore('dealerVehicleList');
            if (store.indexNames.contains('status')) {
              store.deleteIndex('status');
              store.createIndex('status', 'status.name', { unique: false });
            }
          }
        // @ts-ignore: Unreachable code error
        case 31:
          if (db.objectStoreNames.contains('dealerVehicleList')) {
            var request = event.target as IDBRequest;
            var txn = request?.transaction as IDBTransaction;
            var store = txn.objectStore('dealerVehicleList');
            if (!store.indexNames.contains('status,deviceType,generation')) {
              store.createIndex(
                'status,deviceType,generation',
                ['status.name', 'deviceType.name', 'generation.ru'],
                { unique: false }
              );
            }
          }
        // @ts-ignore
        case 32:
          if (db.objectStoreNames.contains('geozonetList')) {
            db.deleteObjectStore('geozonetList');
            db.createObjectStore('geozonetList', {
              keyPath: 'externalId',
            });
          }
        case 51:
        case 52:
          console.log('reach 52 version');
          if (db.objectStoreNames.contains('activeApplicationsList')) {
            console.log('delete activeApplicationsList');
            db.deleteObjectStore('activeApplicationsList');
          }
          break;
        case 53:
          console.log('reach 53 version');
          if (db.objectStoreNames.contains('dealerVehicleList')) {
            console.log('delete dealerVehicleList');
            db.deleteObjectStore('dealerVehicleList');
          }

          if (db.objectStoreNames.contains('serviceVisitList')) {
            console.log('delete serviceVisitList');
            db.deleteObjectStore('serviceVisitList');
          }

          if (db.objectStoreNames.contains('geozonetList')) {
            console.log('delete geozonetList');
            db.deleteObjectStore('geozonetList');
          }

          if (db.objectStoreNames.contains('vehicleComments')) {
            console.log('delete vehicleComments');
            db.deleteObjectStore('vehicleComments');
          }
          break;
        // обновить
      }
    };
  }

  clean(): Observable<void> {
    const openRequest = indexedDB.open('dealerVehicleDB');
    const completedCountSubject = new BehaviorSubject<number>(0);
    const hasErrorSubject = new Subject<boolean>();

    openRequest.onerror = () => hasErrorSubject.next(true);
    openRequest.onsuccess = () => {
      const db = openRequest.result;
      // очистка
      if (db.objectStoreNames.contains('dealerVehicleList')) {
        const transaction = db.transaction('dealerVehicleList', 'readwrite');
        const vehicles = transaction.objectStore('dealerVehicleList');
        const req = vehicles.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }
      if (db.objectStoreNames.contains('dealersList')) {
        const transaction = db.transaction('dealersList', 'readwrite');
        const dealersList = transaction.objectStore('dealersList');
        const req = dealersList.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }
      if (db.objectStoreNames.contains('serviceVisitList')) {
        const transaction = db.transaction('serviceVisitList', 'readwrite');
        const dealersList = transaction.objectStore('serviceVisitList');
        const req = dealersList.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }

      if (db.objectStoreNames.contains('geozonetList')) {
        const transaction = db.transaction('geozonetList', 'readwrite');
        const dealersList = transaction.objectStore('geozonetList');
        const req = dealersList.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }
      if (db.objectStoreNames.contains('activeApplicationsList')) {
        const transaction = db.transaction(
          'activeApplicationsList',
          'readwrite'
        );
        const dealersList = transaction.objectStore('activeApplicationsList');
        const req = dealersList.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }
      if (db.objectStoreNames.contains('vehicleComments')) {
        const transaction = db.transaction('vehicleComments', 'readwrite');
        const commentsList = transaction.objectStore('vehicleComments');
        const req = commentsList.clear();
        req.onsuccess = () =>
          completedCountSubject.next(completedCountSubject.value + 1);
        req.onerror = () => hasErrorSubject.next(true);
      }
    };

    return merge(
      completedCountSubject.pipe(filter((count) => count >= 1)),
      hasErrorSubject.pipe(
        tap(() => {
          throw new Error('Cannot clean db');
        })
      )
    ).pipe(
      take(1),
      map(() => {})
    );
  }
}
