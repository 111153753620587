export interface ApiExceptionPayload {
  meta: {
    code: string;
    message: string;
    status: 403;
  };
}

export function isApiExceptionPayload(
  payload: unknown
): payload is ApiExceptionPayload {
  return (
    !!payload &&
    typeof payload === 'object' &&
    Object.prototype.hasOwnProperty.call(payload, 'meta') &&
    Object.prototype.hasOwnProperty.call((<any>payload).meta, 'code')
  );
}
