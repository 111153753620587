import { Injectable } from '@angular/core';
import { Theme } from 'src/app/services-global/themes-service/theme.const';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SettingsCommonChanged, SettingsLanguageSet } from './settings.actions';
import { SettingsState } from './settings.state';
import { SettingsStateModule } from './shared/settings-state.interface';

@Injectable()
export class SettingsService {
  @Select(SettingsState.getLanguage) lang$!: Observable<string>;
  @Select(SettingsState.getTheme) theme$!: Observable<Theme>;

  @Select(SettingsState.getCommonSettings) commonSettings$!: Observable<
    Pick<SettingsStateModule, 'language' | 'theme'>
  >;

  constructor(private store: Store) {}

  setLang() {
    this.store.dispatch(new SettingsLanguageSet());
  }

  setCommonSettings(settings: Pick<SettingsStateModule, 'language' | 'theme'>) {
    this.store.dispatch(new SettingsCommonChanged(settings));
  }
}
